import API from '../../utils/api';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import jiraLogo from '../../images/jira.png';
import styled, { ThemeProvider } from 'styled-components';
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardSection,
    Checkbox,
    IconReload,
    InlineFeedback,
    ModalDialog,
    Paragraph,
    Spacer,
    TextButton,
    Trigger,
    TriggerText,
    FormField,
    IconError,
    IconSend,
    IconWaiting,
    IconBell,
    Image,
    Input,
    Label,
    LoadingButton,
    LoadingMask,
    ThemeConstellation,
} from '@zillow/constellation';

import {
    API_ADMIN_AGENT_REQUESTS_FUNC,
    API_ADMIN_AGENTS_OVERDUE,
    API_REPLAY_REQUESTS,
    API_IS_USER_HAVE_FULL_ADMIN_ACCESS,
    API_ADMIN_UPDATE_DATA_AGENT_STATUS,
    API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM,
} from '../../constants/ApiConstants';
import SigninContext from '../../contexts/SigninContext';

const gridSize = '35% 40% 25%';
const requestButtonGridSize = '18% 31% 20% 9% 15% 10%';

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledBlackSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.trueBlack};
`;

const StyledGeneralGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    margin-top: 2px;
`;

const StyledGeneralContentGridContainer = styled.div`
    align-items: start;
    margin-top: 2px;
    font-size: 14px;
`;

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    margin-top: 10px;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
    font-size: 14px;
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    font-size: 14px;
`;

const StyledRequestsGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${requestButtonGridSize};
    font-size: 14px;
`;

const StyledRequestsGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${requestButtonGridSize};
    font-size: 14px;
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    min-height: 42px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
`;

const StyledH1 = styled.h1`
    display: inline-block;
    line-height: 45px;
    vertical-align: top;
    font-weight: 600;
`;

const StyledIconWaiting = styled.span`
    color: ${props => props.theme.constellation?.colors.yellow400};
`;
const StyledIconError = styled.span`
    color: ${props => props.theme.constellation?.colors.red400};
`;
const StyledIconSend = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;
const StyledIconBell = styled.span`
    color: ${props => props.theme.constellation?.colors.red600};
`;

const StyledLoadingMask = styled.span`
    cursor: pointer;
    vertical-align: top;
    margin: 0 3%;
    text-align: center;
`;

const StyledIconLabel = styled.span`
    display: block;
    line-height: 14px;
    margin: 10px 0;
`;

const StyledJiraIconLabel = styled.span`
    display: block;
    line-height: 5px;
    margin: 10px 0;
    color: ${props => props.theme.constellation?.colors.blue400};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
`;

const StyledTableRow = styled.div`
    display: inline-block;
    padding: 2px 2px 1px 2px;
`;

const StyledCardHeader = styled(CardHeader)`
    padding-left: 10px;
    padding-top: 13px;
    background-color: ${props => props.theme.constellation?.colors.gray200};
`;

const StyleCard = styled(Card)`
    padding: 2px
    margin-bottom: 30px
    margin-top: 30px
    border-bottom: 3px solid #C3CDDB
    box-shadow: None
`;

const StyledRequestsGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${requestButtonGridSize};
    margin-top: 10px;
`;

const StylePieChartOutter = styled.div`
    position: relative;
    margin: 30px auto 2;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-image: conic-gradient(
        #f7e8ff ${props => props.slaPercentage}%,
        #b827ff ${props => props.slaPercentage}%
    );
`;

const StylePieChartInner = styled.div`
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
`;

const StylePieChartSpan = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 2px;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const StyledLoadingButtonDiv = styled.span`
    div[class^='StyledLoadingMask'] {
        margin-left: 14px;
    }
    button {
        padding: 9px 20px;
        height: 40px;
    }
`;

const StyledFooterButtonsContainer = styled.div`
    width: 100%;
    text-align: right;
`;

const StyledReplayAlertContainer = styled.div`
    text-align: left;
    margin-top: 10px;
`;

const StyledInlineFeedbackDiv = styled.div`
    text-align: left;
`;

const DisplayAgentDetails = props => {
    const {
        state: { csrfToken },
    } = useContext(SigninContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [requestsStatus, setRequestsStatus] = useState([]);
    const [requests, setRequests] = useState([]);
    const [isRequestPendingLoading, setIsRequestPendingLoading] = useState(false);
    const [isRequestSentLoading, setIsRequestSentLoading] = useState(false);
    const [isRequestErrorLoading, setIsRequestErrorLoading] = useState(false);
    const [isRequestJiraTicketLoading, setIsRequestJiraTicketLoading] = useState(false);
    const [isRequestOverdueLoading, setIsRequestOverdueLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [replayUserRequestIds, setReplayUserRequestIds] = useState({});
    const [replayAgentId, setReplayAgentId] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserBelongsToAuthorizedTeam, setIsUserBelongsToAuthorizedTeam] = useState(false);
    const [isUserBelongsToDataAgent, setIsUserBelongsToDataAgent] = useState(false);
    const [isUserInAgentTeam, setIsUserInAgentTeam] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState(false);
    const [active, setActive] = useState('ACTIVE');
    const [inactive, setInactive] = useState('INACTIVE');
    const [value, setValue] = useState('');

    const setReplayRequest = e => {
        setErrorMessage('');
        setIsFinished(false);
        setInfoMessage('');
        const agentId = e.target.dataset.agent;
        const messageType = e.target.dataset.message;
        const userRequestId = e.target.id;
        let userRequestIdsList = replayUserRequestIds;

        if (replayAgentId !== agentId) {
            setReplayAgentId(agentId);
        }

        if (e.target.checked) {
            if (e.target.id === 'selectAll') {
                requests.forEach((obj, i) => {
                    document.getElementById(obj.requestId).checked = true;
                    userRequestIdsList[obj.requestId] = obj.messageType;
                    setReplayAgentId(obj.agentId);
                });
            } else {
                userRequestIdsList[userRequestId] = messageType;
            }
            userRequestIdsList[userRequestId] = messageType;
        } else {
            if (e.target.id === 'selectAll') {
                requests.forEach((obj, i) => {
                    document.getElementById(obj.requestId).checked = false;
                    delete userRequestIdsList[obj.requestId];
                });
            } else {
                delete userRequestIdsList[userRequestId];
            }
        }
        delete userRequestIdsList['selectAll'];
        setReplayUserRequestIds(userRequestIdsList);
        const numberOfUserRequestIds = Object.keys(replayUserRequestIds).length;
        if (numberOfUserRequestIds > 0) {
            setInfoMessage(
                getInfoMessage(numberOfUserRequestIds + ' requests are selected to retry!')
            );
            return;
        }
    };

    const handleResendRequest = () => {
        setErrorMessage('');
        if (Object.keys(replayUserRequestIds).length < 1) {
            setErrorMessage(getErrorMessage('Please select atleast one request to replay..!'));
            return;
        }
        setIsLoading(true);
        API({
            url: API_REPLAY_REQUESTS(replayAgentId, replayUserRequestIds),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'POST',
        })
            .then(res => {
                if (res) {
                    setIsFinished(true);
                    setIsLoading(false);
                    props.data.requestAgents.forEach(agent => {
                        if (agent.agentId === replayAgentId) {
                            agent.requestAgentStatus = 'RETRY';
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    };

    const getErrorMessage = errorMessageText => {
        return (
            <StyledInlineFeedbackDiv>
                <InlineFeedback appearance="error">{errorMessageText}</InlineFeedback>
            </StyledInlineFeedbackDiv>
        );
    };

    const getInfoMessage = infoMessageText => {
        return (
            <StyledInlineFeedbackDiv>
                <InlineFeedback appearance="info">{infoMessageText}</InlineFeedback>
            </StyledInlineFeedbackDiv>
        );
    };

    const checkUserBelongsToAgentTeam = zodiacServiceName => {
        setErrorMessage('');
        setIsUserInAgentTeam(true);
        API({
            url: API_IS_USER_HAVE_FULL_ADMIN_ACCESS(zodiacServiceName),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'GET',
        })
            .then(res => {
                if (res) {
                    setIsUserBelongsToDataAgent(res.data);
                    setIsUserInAgentTeam(true);
                }
            })
            .catch(error => {
                console.log(error);
                setIsUserInAgentTeam(false);
            });
    };

    const handleOnClose = () => {
        setReplayUserRequestIds({});
        setIsFinished(false);
        setIsLoading(false);
        setIsModalOpen(false);
        setErrorMessage('');
        setInfoMessage('');
    };

    const updateAgentStatusBodyDialog = (agentName, operationType, close) => (
        <ModalDialog
            {...props}
            wide
            body="Do you really want to ACTIVATE/DEACTIVATE this agent?"
            footer={
                <StyledFooterButtonsContainer>
                    <form>
                        <FormField spacingBottom="sm" style={{ display: 'flex' }}>
                            <Label style={{ lineHeight: '40px', marginRight: '10px' }}>
                                Enter Jira Ticket Number:{' '}
                            </Label>
                            <Input
                                style={{ width: '43%' }}
                                name="jiraTicketNumber"
                                id="jiraTicketNumber"
                                placeholder="put valid Jira Ticket Number here"
                                onChange={e => setValue(e.target.value)}
                            />
                        </FormField>
                        {errorMessage}
                        <ButtonGroup>
                            <TextButton onClick={close}>Cancel</TextButton>
                            {isLoading ? (
                                <StyledLoadingButtonDiv>
                                    <LoadingButton size="lg" buttonType="primary" loading>
                                        <IconReload size="xs" /> Processing
                                    </LoadingButton>
                                </StyledLoadingButtonDiv>
                            ) : (
                                <Button
                                    buttonType="primary"
                                    type="submit"
                                    onClick={() =>
                                        updateDataAgentStatus(agentName, operationType, value)
                                    }
                                >
                                    <IconReload size="xs" /> Process
                                </Button>
                            )}
                        </ButtonGroup>
                    </form>
                    {isFinished && (
                        <StyledReplayAlertContainer>
                            <Alert appearance="success" body="Updated successfully...!" />
                        </StyledReplayAlertContainer>
                    )}
                </StyledFooterButtonsContainer>
            }
        />
    );

    const updateDataAgentStatus = (agentName, operationType, jiraTicketNumber) => {
        setIsLoading(true);
        API({
            url: API_ADMIN_UPDATE_DATA_AGENT_STATUS(agentName, jiraTicketNumber, operationType),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'POST',
        })
            .then(res => {
                if (res) {
                    setIsFinished(true);
                    setIsLoading(false);
                    if (operationType === 'deactivation') {
                        setInactive('INACTIVE');
                        props.data.agentStatus = inactive;
                    } else if (operationType === 'activation') {
                        setActive('ACTIVE');
                        props.data.agentStatus = active;
                    }
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(getErrorMessage('Something went wrong. Please try again later..!'));
                setIsLoading(false);
            });
    };

    const requestStatus = (status, agentName, isStrong = false) => {
        API({
            url: API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'GET',
        })
            .then(res => {
                if (res) {
                    setIsUserBelongsToAuthorizedTeam(res.data);
                }
            })
            .catch(error => {
                console.log(error);
                setIsUserBelongsToAuthorizedTeam(false);
            });
        switch (status) {
            case 'ACTIVE':
                if (isUserBelongsToAuthorizedTeam) {
                    return (
                        <>
                            <StyledGreenSpan>
                                {isStrong ? <strong>{status}</strong> : status} |
                            </StyledGreenSpan>
                            <Trigger
                                triggered={updateAgentStatusBodyDialog(agentName, 'deactivation')}
                                onClose={handleOnClose}
                            >
                                <TextButton> De-activate</TextButton>
                            </Trigger>
                        </>
                    );
                } else {
                    return (
                        <>
                            <StyledGreenSpan>
                                {isStrong ? <strong>{status}</strong> : status}
                            </StyledGreenSpan>
                        </>
                    );
                }
            case 'INACTIVE':
                if (isUserBelongsToAuthorizedTeam) {
                    return (
                        <>
                            <StyledRedSpan>
                                {isStrong ? <strong>{status}</strong> : status}
                            </StyledRedSpan>
                            <Trigger
                                triggered={updateAgentStatusBodyDialog(agentName, 'activation')}
                                onClose={handleOnClose}
                            >
                                <TextButton> | Activate</TextButton>
                            </Trigger>
                        </>
                    );
                } else {
                    return (
                        <StyledRedSpan>
                            {isStrong ? <strong>{status}</strong> : status}
                        </StyledRedSpan>
                    );
                }

            default:
                return (
                    <StyledBlackSpan>
                        {isStrong ? <strong>{status}</strong> : status}
                    </StyledBlackSpan>
                );
        }
    };

    const getUserIdentifiers = props => {
        let userIdentifiers = [];
        props.data.userIdentifierTypes.forEach((obj, i) => {
            userIdentifiers.push(
                <StyledGeneralContentGridContainer key={i}>
                    <StyledTableRow>{obj}</StyledTableRow>
                </StyledGeneralContentGridContainer>
            );
        });
        return userIdentifiers;
    };

    const userIdentifier = <Spacer>{getUserIdentifiers(props)}</Spacer>;

    const getDataCategories = props => {
        let dataCategories = [];
        props.data.dataCategories.forEach((obj, i) => {
            dataCategories.push(
                <StyledGeneralContentGridContainer key={i}>
                    <StyledTableRow>{obj}</StyledTableRow>
                </StyledGeneralContentGridContainer>
            );
        });
        return dataCategories;
    };

    const dataCategories =
        props.data.dataCategories.length === 0 ? (
            <Alert appearance="warning" body="No data categories found." />
        ) : (
            <Spacer>{getDataCategories(props)}</Spacer>
        );

    const getMessageTypes = props => {
        let messageTypes = [];
        props.data.messageTypes.forEach((obj, i) => {
            messageTypes.push(
                <StyledGeneralContentGridContainer key={i}>
                    <StyledTableRow>{obj}</StyledTableRow>
                </StyledGeneralContentGridContainer>
            );
        });
        return messageTypes;
    };

    const messageTypes = <Spacer>{getMessageTypes(props)}</Spacer>;

    const getGeneralInformation = props => {
        let generalInformations = [];
        generalInformations.push(
            <StyledGeneralGridContainer>
                <StyledRow>{userIdentifier}</StyledRow>
                <StyledRow>{dataCategories}</StyledRow>
                <StyledRow>{messageTypes}</StyledRow>
            </StyledGeneralGridContainer>
        );
        return generalInformations;
    };

    const generalInformation = (
        <Spacer>
            <StyledGeneralGridContainer>
                <StyledHeader>User Identifiers</StyledHeader>
                <StyledHeader>Data Categories</StyledHeader>
                <StyledHeader>Message Types</StyledHeader>
            </StyledGeneralGridContainer>
            {getGeneralInformation(props)}
        </Spacer>
    );

    const getAgentDeleteDataDomains = props => {
        let content = [];
        props.data.agentDeleteDataDomains.sort(function(dataDomain1, dataDomain2) {
            return dataDomain1.BRAND.localeCompare(dataDomain2.BRAND);
        });
        const agentDeleteDataDomains = JSON.parse(
            JSON.stringify(props.data.agentDeleteDataDomains).replace(/\s(?=\w+":)/g, '_')
        );
        agentDeleteDataDomains.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            content.push(
                <StyledGridContainer key={i}>
                    <StyledRow>{obj.BRAND}</StyledRow>
                    <StyledRow>{obj.LOB}</StyledRow>
                    <StyledRow>{obj.SUBJECT_AREA}</StyledRow>
                </StyledGridContainer>
            );
        });
        return content;
    };

    const agentDeleteDataDomains =
        props.data.agentDeleteDataDomains.length <= 0 ? (
            <Alert appearance="warning" body="No agent delete data domains found." />
        ) : (
            <Spacer>
                <StyledGridContainer>
                    <StyledHeader>BRAND</StyledHeader>
                    <StyledHeader>LOB</StyledHeader>
                    <StyledHeader>SUBJECT AREA</StyledHeader>
                </StyledGridContainer>
                {getAgentDeleteDataDomains(props)}
            </Spacer>
        );

    const getRequestSla = requestcreateDate => {
        // deadlineDate represents the same date as requestcreateDate but with the time portion set to 00:00:00.
        const deadlineDate = new Date(new Date(requestcreateDate).getTime());
        // adds 45 days to deadlineDate (deadlineDate will be 45 days after the requestcreateDate)
        deadlineDate.setDate(deadlineDate.getDate() + 45);
        // calculates the time difference in milliseconds between deadlineDate and the current date and time.
        const diffInMs = deadlineDate.getTime() - new Date().getTime();
        // converts the time difference from milliseconds to days by dividing it by the number of milliseconds in a day
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    };

    const getrequestSlaRemaining = requestcreateDate => {
        // calculating the number of days between the current date and the request created date.
        const daysSinceCreation = Math.floor(
            (new Date() - new Date(requestcreateDate)) / (1000 * 60 * 60 * 24)
        );
        // returning how many 45 day sla have passed since the request creation date.
        return Math.floor((daysSinceCreation / 45) * 100);
    };

    const getDisplayDate = timestamp => {
        const plus0 = num => `0${num.toString()}`.slice(-2);
        const d = new Date(timestamp);
        const year = d.getFullYear();
        const monthTmp = d.getMonth() + 1;
        const month = plus0(monthTmp);
        const date = plus0(d.getDate());
        const hour = plus0(d.getHours());
        const minute = plus0(d.getMinutes());
        const second = plus0(d.getSeconds());
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    };

    const getAgentRequests = () => {
        let content = [];
        requests.forEach((obj, i) => {
            const StyledRequestsGridContainer =
                i % 2 ? StyledRequestsGridEvenContainer : StyledRequestsGridOddContainer;
            content.push(
                <StyledRequestsGridContainer key={i}>
                    <StyledRow>{getDisplayDate(obj.createDate)}</StyledRow>
                    <StyledRow>{obj.userRequestId ? obj.userRequestId : obj.requestId}</StyledRow>
                    <StyledRow>{obj.messageType}</StyledRow>
                    <StyledRow>
                        <StylePieChartOutter slaPercentage={getrequestSlaRemaining(obj.createDate)}>
                            <StylePieChartInner>
                                <StylePieChartSpan>
                                    <StyledTriggerText>
                                        {getRequestSla(obj.createDate)}
                                    </StyledTriggerText>
                                </StylePieChartSpan>
                            </StylePieChartInner>
                        </StylePieChartOutter>
                    </StyledRow>
                    <StyledRow>
                        {['JIRA_TICKET_CREATED'].includes(requestsStatus[0]) &&
                            (obj.jiraTicketNumber ? (
                                <a
                                    href={
                                        'https://zillowgroup.atlassian.net/browse/' +
                                        obj.jiraTicketNumber
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {obj.jiraTicketNumber}
                                </a>
                            ) : (
                                '-'
                            ))}
                    </StyledRow>
                    <StyledRow>
                        {['ERROR', 'REQUEST_SENT', 'JIRA_TICKET_CREATED'].includes(
                            requestsStatus[0]
                        ) && (
                            <Checkbox
                                controlId={obj.requestId}
                                data-agent={obj.agentId}
                                data-message={obj.messageType}
                                onClick={setReplayRequest}
                            />
                        )}
                    </StyledRow>
                </StyledRequestsGridContainer>
            );
        });
        return content;
    };

    const isButtonLoading = buttonName => {
        if (buttonName === 'REQUEST_PENDING') {
            return isRequestPendingLoading && isUserInAgentTeam;
        } else if (buttonName === 'REQUEST_SENT') {
            return isRequestSentLoading && isUserInAgentTeam;
        } else if (buttonName === 'JIRA_TICKET_CREATED') {
            return isRequestJiraTicketLoading && isUserInAgentTeam;
        } else if (buttonName === 'ERROR') {
            return isRequestErrorLoading && isUserInAgentTeam;
        } else if (buttonName === 'OVERDUE') {
            return isRequestOverdueLoading && isUserInAgentTeam;
        }
    };

    const agentRequests = (agentZodiacServiceName, agentName, requestStatus) => {
        checkUserBelongsToAgentTeam(agentZodiacServiceName);
        let report_url = API_ADMIN_AGENT_REQUESTS_FUNC(agentName, requestStatus);
        if (requestStatus[0] === 'REQUEST_PENDING') {
            setIsRequestPendingLoading(true);
        } else if (requestStatus[0] === 'REQUEST_SENT') {
            setIsRequestSentLoading(true);
        } else if (requestStatus[0] === 'JIRA_TICKET_CREATED') {
            setIsRequestJiraTicketLoading(true);
        } else if (requestStatus[0] === 'ERROR') {
            setIsRequestErrorLoading(true);
        } else if (requestStatus[0] === 'OVERDUE') {
            setIsRequestOverdueLoading(true);
            report_url = API_ADMIN_AGENTS_OVERDUE(agentName);
        }
        setRequestsStatus(requestStatus);
        API({
            url: report_url,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'GET',
        })
            .then(res => {
                if (res) {
                    setIsRequestPendingLoading(false);
                    setIsRequestSentLoading(false);
                    setIsRequestErrorLoading(false);
                    setIsRequestJiraTicketLoading(false);
                    setIsRequestOverdueLoading(false);
                    setIsModalOpen(true);
                    setRequests(res.data);
                }
            })
            .catch(error => {
                console.log(error);
                setIsErrorModalOpen(true);
                setIsRequestPendingLoading(false);
                setIsRequestSentLoading(false);
                setIsRequestErrorLoading(false);
                setIsRequestJiraTicketLoading(false);
                setIsRequestOverdueLoading(false);
            });
    };

    const theme = {
        ...ThemeConstellation,
    };

    return (
        <div>
            <Card>
                <React.Fragment>
                    <CardHeader>
                        Agent : <strong>{props.data.agentName}</strong>
                    </CardHeader>
                    <CardSection>
                        <Paragraph>
                            Agent Status :
                            {requestStatus(props.data.agentStatus, props.data.agentName, true)}
                        </Paragraph>
                        <Paragraph>
                            Zodiac Team Name :{' '}
                            <a
                                href={
                                    'https://zodiac.zgtools.net/teams/' + props.data.zodiacTeamName
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <strong>{props.data.zodiacTeamName.toUpperCase()}</strong>
                            </a>
                        </Paragraph>
                        <Paragraph>
                            Zodiac Service Name :{' '}
                            <a
                                href={
                                    'https://zodiac.zgtools.net/services/' +
                                    props.data.zodiacServiceName
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <strong>{props.data.zodiacServiceName.toUpperCase()}</strong>
                            </a>
                        </Paragraph>
                        <Paragraph>
                            Priority Level : <strong>{props.data.priorityLevel}</strong>
                        </Paragraph>
                        <Paragraph>
                            Agent Delete Type : <strong>{props.data.agentDeleteType}</strong>
                        </Paragraph>
                    </CardSection>
                </React.Fragment>
            </Card>
            <StyleCard>
                <React.Fragment>
                    <StyledCardHeader>General Information</StyledCardHeader>
                    <CardSection>{generalInformation}</CardSection>
                </React.Fragment>
            </StyleCard>
            <StyleCard>
                <React.Fragment>
                    <StyledCardHeader>Agent Delete Data Domains</StyledCardHeader>
                    <CardSection>{agentDeleteDataDomains}</CardSection>
                </React.Fragment>
            </StyleCard>
            {!props.isAllAgentsPage && (
                <StyleCard>
                    <React.Fragment>
                        <StyledCardHeader>Agent Requests</StyledCardHeader>
                        <div>
                            <StyledH1>Show Requests in state:</StyledH1>
                            <ThemeProvider theme={theme}>
                                <StyledLoadingMask>
                                    <LoadingMask loading={isButtonLoading('REQUEST_PENDING')}>
                                        <StyledIconWaiting
                                            onClick={() =>
                                                agentRequests(
                                                    props.data.zodiacServiceName,
                                                    props.data.agentName,
                                                    ['REQUEST_PENDING']
                                                )
                                            }
                                        >
                                            <IconWaiting size="lg" />
                                            <StyledIconLabel>PENDING</StyledIconLabel>
                                        </StyledIconWaiting>
                                    </LoadingMask>
                                </StyledLoadingMask>

                                <StyledLoadingMask>
                                    <LoadingMask loading={isButtonLoading('REQUEST_SENT')}>
                                        <StyledIconSend
                                            onClick={() =>
                                                agentRequests(
                                                    props.data.zodiacServiceName,
                                                    props.data.agentName,
                                                    ['REQUEST_SENT', 'RETRY_SENT']
                                                )
                                            }
                                        >
                                            <IconSend size="lg" />
                                            <StyledIconLabel>REQUEST SENT</StyledIconLabel>
                                        </StyledIconSend>
                                    </LoadingMask>
                                </StyledLoadingMask>

                                <StyledLoadingMask>
                                    <LoadingMask loading={isButtonLoading('JIRA_TICKET_CREATED')}>
                                        <span
                                            onClick={() =>
                                                agentRequests(
                                                    props.data.zodiacServiceName,
                                                    props.data.agentName,
                                                    ['JIRA_TICKET_CREATED']
                                                )
                                            }
                                        >
                                            <Image
                                                alt="Zillow"
                                                title="Jira ticket create"
                                                height="42px"
                                                src={jiraLogo}
                                            />
                                            <StyledJiraIconLabel>JIRA TICKET</StyledJiraIconLabel>
                                        </span>
                                    </LoadingMask>
                                </StyledLoadingMask>

                                <StyledLoadingMask>
                                    <LoadingMask loading={isButtonLoading('ERROR')}>
                                        <StyledIconError
                                            onClick={() =>
                                                agentRequests(
                                                    props.data.zodiacServiceName,
                                                    props.data.agentName,
                                                    ['ERROR', 'RESPONSE_ERROR']
                                                )
                                            }
                                        >
                                            <IconError size="lg" />
                                            <StyledIconLabel>ERROR</StyledIconLabel>
                                        </StyledIconError>
                                    </LoadingMask>
                                </StyledLoadingMask>

                                <StyledLoadingMask>
                                    <LoadingMask loading={isButtonLoading('OVERDUE')}>
                                        <StyledIconBell
                                            onClick={() =>
                                                agentRequests(
                                                    props.data.zodiacServiceName,
                                                    props.data.agentName,
                                                    ['OVERDUE']
                                                )
                                            }
                                        >
                                            <IconBell size="lg" />
                                            <StyledIconLabel>OVERDUE</StyledIconLabel>
                                        </StyledIconBell>
                                    </LoadingMask>
                                </StyledLoadingMask>
                            </ThemeProvider>
                        </div>
                        {isModalOpen && (
                            <ModalDialog
                                isOpen={isModalOpen}
                                onClose={() => {
                                    setInfoMessage('');
                                    setIsModalOpen(false);
                                }}
                                wide
                                header={
                                    <Paragraph>
                                        Requests In{' '}
                                        {requestsStatus.length > 0
                                            ? requestsStatus.map(status => `${status}`).join('/')
                                            : ''}{' '}
                                        State
                                    </Paragraph>
                                }
                                body={
                                    <div>
                                        {requests.length > 0 ? (
                                            <>
                                                <StyledRequestsGridContainer>
                                                    <StyledHeader>Create Date</StyledHeader>
                                                    <StyledHeader>Request Id</StyledHeader>
                                                    <StyledHeader>Message Type</StyledHeader>
                                                    <StyledHeader>SLA</StyledHeader>
                                                    <StyledHeader>
                                                        {['JIRA_TICKET_CREATED'].includes(
                                                            requestsStatus[0]
                                                        ) && 'JIra Ticket'}
                                                    </StyledHeader>
                                                    <StyledHeader>
                                                        {[
                                                            'ERROR',
                                                            'REQUEST_SENT',
                                                            'JIRA_TICKET_CREATED',
                                                        ].includes(requestsStatus[0]) && (
                                                            <Checkbox
                                                                controlId="selectAll"
                                                                onClick={setReplayRequest}
                                                            />
                                                        )}
                                                    </StyledHeader>
                                                </StyledRequestsGridContainer>
                                                <React.Fragment>
                                                    {getAgentRequests()}
                                                </React.Fragment>
                                            </>
                                        ) : (
                                            <StyledParagraph>
                                                There is no requests found
                                            </StyledParagraph>
                                        )}
                                    </div>
                                }
                                footer={
                                    <>
                                        {['ERROR', 'REQUEST_SENT', 'JIRA_TICKET_CREATED'].includes(
                                            requestsStatus[0]
                                        ) && (
                                            <StyledFooterButtonsContainer>
                                                {infoMessage}
                                                {errorMessage}
                                                <ButtonGroup>
                                                    {requests.length > 0 ? (
                                                        <>
                                                            <Button
                                                                buttonType="primary"
                                                                onClick={handleOnClose}
                                                            >
                                                                Close
                                                            </Button>
                                                            {isLoading ? (
                                                                <StyledLoadingButtonDiv>
                                                                    <LoadingButton
                                                                        size="lg"
                                                                        buttonType="primary"
                                                                        loading
                                                                    >
                                                                        <IconReload size="xs" />{' '}
                                                                        Processing
                                                                    </LoadingButton>
                                                                </StyledLoadingButtonDiv>
                                                            ) : (
                                                                <Button
                                                                    disabled={
                                                                        !isUserBelongsToDataAgent
                                                                    }
                                                                    buttonType="primary"
                                                                    onClick={() =>
                                                                        handleResendRequest()
                                                                    }
                                                                >
                                                                    <IconReload size="xs" /> Process
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Button
                                                            buttonType="primary"
                                                            onClick={handleOnClose}
                                                        >
                                                            Close
                                                        </Button>
                                                    )}
                                                </ButtonGroup>
                                                {isFinished && (
                                                    <StyledReplayAlertContainer>
                                                        <Alert
                                                            appearance="success"
                                                            body="Resend successfully...!"
                                                        />
                                                    </StyledReplayAlertContainer>
                                                )}
                                                {!isUserBelongsToDataAgent && !isUserInAgentTeam && (
                                                    <StyledReplayAlertContainer>
                                                        <Alert
                                                            appearance="error"
                                                            body="As you are not the owner of this data agent, you can't replay this request"
                                                        />
                                                    </StyledReplayAlertContainer>
                                                )}
                                            </StyledFooterButtonsContainer>
                                        )}
                                    </>
                                }
                            />
                        )}
                        {isErrorModalOpen && (
                            <ModalDialog
                                isOpen={isErrorModalOpen}
                                onClose={() => {
                                    setIsErrorModalOpen(false);
                                }}
                                wide
                                dividers={false}
                                body={
                                    <StyledParagraph>
                                        Something went wrong. Please try again later..!
                                    </StyledParagraph>
                                }
                            />
                        )}
                    </React.Fragment>
                </StyleCard>
            )}
        </div>
    );
};

DisplayAgentDetails.propTypes = {
    data: PropTypes.any,
    isAllAgentsPage: PropTypes.bool,
};

DisplayAgentDetails.defaultProps = {
    data: [],
    isAllAgentsPage: false,
};

export default DisplayAgentDetails;
